@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,400;1,100&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
 html{
  @apply overflow-hidden;
 }

  * {
    @apply outline-none focus:outline-none
  }

  body {
    @apply overflow-hidden font-inter text-lg md:text-base bg-siteBG2 text-dark;
  }

  /* FOR LAZY STYLE */
  .lazy {
    @apply relative max-w-full w-full;
  }

  .lazy::before {
    @apply absolute content-[""] w-full h-full bg-200 left-0 top-0 bg-lazyGradient animate-lazy rounded-md z-[1];
  }

  /* Scrollbar width */

  @-moz-document url-prefix() {
    * {
      scrollbar-color: rgba(0, 0, 0, 0.1) #fff;
      scrollbar-width: thin !important;
    }
  }
  
  *::-webkit-scrollbar {
    @apply w-0 h-1;
  }

  /* Scrollbar Track */
  *::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Scrollbar Handle */
  *::-webkit-scrollbar-thumb {
    @apply rounded-full bg-black/10 hover:bg-black/30;
  }

  /* ScrollBar Hide */
  .no-scrollbar::-webkit-scrollbar {
    @apply w-0 h-0
  }

  .with-scrollbar {
    &::-webkit-scrollbar {
      @apply !w-1 !h-1;
    }
  }

  body>div:last-child {
    @apply !z-[999]
  }

  .action-button{
    @apply w-42px h-42px inline-flex items-center justify-center rounded-full transition-all duration-300 active:scale-90 cursor-pointer select-none
  }

  .action-button.green-mail-btn {
    @apply bg-green-600/10 text-green-900 hover:bg-green-900 hover:text-white
  }

  .action-button.green-btn{
    @apply bg-secondary/10 text-secondary hover:bg-secondary hover:text-white
  }
  .action-button.primary-btn{
    @apply bg-primary/10 text-primary hover:bg-primary hover:text-white
  }
  .action-button.red-btn{
    @apply bg-danger/10 text-red-600 hover:bg-red-600 hover:text-white
  }
  .action-button.blue-btn{
    @apply bg-ic_4/10 text-ic_4 hover:bg-ic_4 hover:text-white
  }


  .action-button .invoice-terms-image{
    filter: brightness(0) saturate(100%) invert(11%) sepia(86%) saturate(3893%) hue-rotate(188deg) brightness(85%) contrast(91%);
    transition: filter 0.3s ease;
  }

  .action-button:hover .invoice-terms-image{
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(128deg) brightness(102%) contrast(101%);
  } 

  select{
    @apply bg-arrowDown bg-[calc(100%_-_10px)_center] bg-no-repeat bg-[length:16px] appearance-none;
  }
 
  /* DATEPICKER */
  .calendarClassName .react-datepicker{
    @apply border-borderColor
  }
  .calendar-wrapper .react-datepicker-wrapper {
    @apply w-full;
  }
  .calendarClassName .react-datepicker__month-container {
    @apply float-none;
  }
  .calendarClassName .react-datepicker__header {
    @apply bg-white border-0 pb-0 !rounded-t-xl pt-0;
  }
  .calendarClassName .react-datepicker__current-month {
    @apply mb-5 translate-y-5;
  }
  .calendarClassName .react-datepicker__header__dropdown--scroll {
    @apply py-3 font-medium;
  }
  .calendarClassName .react-datepicker__month-read-view--down-arrow,
  .calendarClassName .react-datepicker__year-read-view--down-arrow {
    @apply top-[3px] border-t-2 border-r-2 -right-4;
  }
  .calendarClassName .react-datepicker__month-dropdown-container--scroll,
  .calendarClassName .react-datepicker__year-dropdown-container--scroll {
    @apply !mx-1 bg-gray-200 p-1.5 min-w-[75px] !pe-6 rounded-md;
  }
  .calendarClassName .react-datepicker__navigation {
    @apply top-3 shadow flex items-center justify-center rounded-md;
  }
  .calendarClassName .react-datepicker__year-dropdown--scrollable,
  .calendarClassName .react-datepicker__month-dropdown {
    @apply shadow bg-white border-none flex flex-wrap p-1;
  }

  .calendarClassName .react-datepicker__year-option,
  .calendarClassName .react-datepicker__month-option {
    @apply py-1 hover:bg-primary hover:text-white rounded-md mx-0 relative w-1/2;
  }
  .calendarClassName .react-datepicker__month-dropdown {
    @apply w-[220px];
  }
  .calendarClassName .react-datepicker__navigation--years-upcoming,
  .calendarClassName .react-datepicker__navigation--years-previous {
    @apply rounded-none hover:bg-transparent overflow-unset p-0 inline-block w-4 h-4 relative shadow-none before:absolute before:-top-[4px] before:border-l-0 before:border-b-0 before:border-t-2 before:border-r-2 before:right-0 before:border-gray-700 before:border-solid before:w-3 before:h-3 before:-rotate-45;
  }
  .calendarClassName .react-datepicker__navigation--years-previous {
    @apply rotate-180 -top-2;
  }
  .calendarClassName .react-datepicker__year-option--selected_year,
  .calendarClassName .react-datepicker__month-option--selected_month {
    @apply bg-primary text-white;
  }
  .calendarClassName .react-datepicker__year-option--selected,
  .calendarClassName .react-datepicker__month-option--selected {
    @apply absolute left-1.5 top-1;
  }
  .calendarClassName .react-datepicker__navigation--previous {
    @apply left-4;
  }
  .calendarClassName .react-datepicker__navigation--next {
    @apply right-4;
  }
  .calendarClassName .react-datepicker__navigation-icon--previous,
  .calendarClassName .react-datepicker__navigation-icon--next {
    @apply left-0 top-0 w-full h-full text-[0px] rotate-45;
  }
  .calendarClassName .react-datepicker__navigation-icon--previous {
    @apply rotate-[225deg];
  }
  .calendarClassName .react-datepicker__navigation-icon--previous::before,
  .calendarClassName .react-datepicker__navigation-icon--next::before {
    @apply text-black w-9px h-9px border-r-2 border-t-2 border-black left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 !rotate-0;
  }
  .calendarClassName .react-datepicker__triangle {
    @apply before:!border-b-white after:!border-b-black/10;
  }
  .calendarClassName .react-datepicker__header .react-datepicker__day-name {
    @apply opacity-50;
  }
  .calendarClassName .react-datepicker__day-name,
  .calendarClassName .react-datepicker__day,
  .calendarClassName .react-datepicker__time-name {
    @apply w-9 text-black leading-[34px] border border-solid border-transparent m-0.5 text-sm font-medium;
  }
  .calendarClassName .react-datepicker__day-name,
  .calendarClassName .react-datepicker__day,
  .calendarClassName .react-datepicker__time-name,
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    @apply !rounded-full;
  }
  .calendarClassName
    .react-datepicker__day.react-datepicker__day--keyboard-selected {
    @apply bg-transparent text-black;
  }

  .calendarClassName .react-datepicker__day--disabled,
  .calendarClassName .react-datepicker__month-text--disabled,
  .calendarClassName .react-datepicker__quarter-text--disabled,
  .calendarClassName .react-datepicker__year-text--disabled {
    @apply opacity-50;
  }
  .calendarClassName .react-datepicker__day--selected,
  .calendarClassName .react-datepicker__day--in-selecting-range,
  .calendarClassName .react-datepicker__day--in-range,
  .calendarClassName .react-datepicker__month-text--selected,
  .calendarClassName .react-datepicker__month-text--in-selecting-range,
  .calendarClassName .react-datepicker__month-text--in-range,
  .calendarClassName .react-datepicker__quarter-text--selected,
  .calendarClassName .react-datepicker__quarter-text--in-selecting-range,
  .calendarClassName .react-datepicker__quarter-text--in-range,
  .calendarClassName .react-datepicker__year-text--selected,
  .calendarClassName .react-datepicker__year-text--in-selecting-range,
  .calendarClassName .react-datepicker__year-text--in-range {
    @apply !bg-primary !text-white;
  }
  .calendarClassName .react-datepicker__input-time-container {
    @apply relative float-none m-10px bg-gray-200 w-[120px] py-2 px-10px rounded-md;
  }
  .calendarClassName .react-datepicker-time__caption {
    @apply text-base font-inter font-normal;
  }
  .calendarClassName
    .react-datepicker-time__input
    input::-webkit-calendar-picker-indicator {
    @apply absolute left-0 right-0 w-full h-full opacity-0 cursor-pointer;
  }
  .calendarClassName .react-datepicker-time__input input {
    @apply bg-transparent text-base font-inter font-normal text-center !ms-0;
  }
  .calendarClassName .react-datepicker-time__input-containert {
    @apply !ms-0 max-w-[calc(100%_-_40px)] ps-10px w-full;
  }
  .calendarClassName .react-datepicker-time__input {
    @apply ms-0;
  }
  .calendarClassName
    .react-datepicker__month-container
    .react-datepicker-year-header {
    @apply pt-5;
  }
  .calendarClassName .react-datepicker__monthPicker {
    @apply mt-2;
  }
  .calendarClassName
    .react-datepicker__monthPicker
    .react-datepicker__month-text {
    @apply h-8 inline-flex items-center justify-center text-15px;
  }

  /* CERTIFICATE AND EMAIL VIEW STYLE */
  .content-wrapper > * {
    @apply mb-6;
  }
  .content-wrapper h1 {
    @apply text-3xl xl:text-4xl font-medium;
  }
  
  .content-wrapper h2 {
    @apply text-2xl xl:text-3xl font-medium;
  }
  
  .content-wrapper h3 {
    @apply text-xl xl:text-2xl font-medium;
  }
  .content-wrapper p,
  .content-wrapper li {
    @apply text-sm leading-normal text-black text-center font-montserrat;
  }
  .content-wrapper ul {
    @apply p-0 m-0 list-none mt-12 mb-5 flex flex-col items-center;
  }
  .content-wrapper li {
    @apply mb-4 relative block w-fit before:absolute before:content-[''] before:-left-3.5 before:top-2.5 before:w-1.5 before:h-1.5 before:rounded-full before:bg-black;
  }
  .content-wrapper img {
    @apply mx-auto max-h-[450px] w-full object-contain mb-6;
  }

  .content-wrapper .sign,
  .content-wrapper .sign p{
    @apply text-left
  }
  .content-wrapper .sign img{
    @apply mr-auto ml-0;
  }
  .content-wrapper .sign + div{
    @apply font-montserrat;
  }
  .leading-snug a{
    @apply inline bg-black text-[#c2ff34] font-montserrat font-bold;
  }

  .content-wrapper p{
    @apply font-montserrat mb-1
  }
  .content-wrapper p:nth-last-child(5){
    @apply mt-10
  }
  .content-wrapper p:nth-last-child(4), .content-wrapper p:nth-last-child(5){
    @apply text-left
  }

  .content-wrapper p:nth-last-child(1), .content-wrapper p:nth-last-child(2){
    @apply text-[10px] font-montserrat text-center leading-snug
  }

}
.rmdp-container .rmdp-time-picker div input{
  @apply w-7;
}
.rmdp-container .rmdp-wrapper .rmdp-arrow-container {
  @apply bg-primary/10 text-primary shadow-none flex items-center justify-center opacity-50 hover:opacity-100 hover:bg-primary;
}
.rmdp-container .rmdp-wrapper .rmdp-arrow-container:hover .rmdp-arrow{
  @apply border-white
}
.rmdp-container .rmdp-wrapper .rmdp-arrow-container .rmdp-arrow{
  @apply border-primary m-0 ;
}
.rmdp-container .rmdp-wrapper {
  @apply shadow-card border border-solid border-borderColor;
}
.rmdp-container .rmdp-time-picker div .rmdp-am,
.rmdp-container .rmdp-time-picker div input{
  @apply font-bold
}
.rmdp-container .rmdp-time-picker div .rmdp-am{
  @apply justify-center;
}
.rmdp-container .rmdp-wrapper .rmdp-time-picker {
  @apply !min-w-[160px] py-2.5;
}


/* To Hide Number field arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="search"]::-webkit-search-cancel-button{
  display: none;
}

input[type=number] {
-moz-appearance: textfield;
}

input[type="color"]::-webkit-color-swatch-wrapper {
 @apply p-0
}

.btn{
  all: unset;
}
.sidebar-container .no-scrollbar{
  scrollbar-width: none;
}

/* CONTAINER */
.container{
  @apply max-w-[1706px] mx-auto px-15px;
  /* @apply max-w-[1556px] mx-auto px-15px; */
}

/* FANCY BOX TOOLBAR */
.fancybox__toolbar__column{
  @apply !hidden
}

.rc-mentions-measure {
  @apply absolute inset-0 pointer-events-none -z-1;
}
.rc-mentions-dropdown {
  @apply  max-w-[250px] top-10;
}
.rc-mentions-dropdown-menu {
  @apply p-2 m-0 list-none bg-white shadow-card rounded-lg;
}
.rc-mentions-dropdown-menu-item {
  @apply select-none hover:bg-primary hover:text-white py-2 px-2 rounded-md text-sm font-semibold cursor-pointer transition-all duration-300 flex items-center text-navText;
}
.rc-mentions-dropdown-menu-item-active {
  @apply bg-primary/10 text-primary
}
.rc-mentions-dropdown-menu-item-disabled {
  @apply opacity-50
}

.accessdenied-btn:hover svg path{
  @apply fill-white;
}

.icon-fill-white path{
  @apply fill-white;
}
.react-datepicker-popper{
  @apply !z-[15];
}
.no-chat svg{
  @apply h-[80px] p-3 w-[80px] bg-secondary/30 rounded-full stroke-primary;
}

.no-chat p{
  @apply text-primary;
}

.c-time-picker .inputField{
  @apply border-0 bg-transparent rounded-none px-4 text-center focus:!ring-[0] focus:!shadow-none;
}

.c-notes-data img{
  @apply rounded-lg object-contain w-auto h-auto max-h-[300px] max-w-full my-5;
}

.activity-mentions-input .MentionsInput__control{
  @apply !min-h-[48px] !bg-[#F7FAFC] rounded-[10px];
}

.activity-mentions-input .MentionsInput__input{
  @apply !border-0 !p-[12px_50px_12px_15px];
}

.activity-mentions-input .MentionsInput__highlighter{
  @apply !p-[12px];
}

.main__cn__wrapper  .error404__page {
  @apply min-h-[calc(100dvh_-_250px)] pb-0;
}

.main__cn__wrapper  .error404__page .error404__contant  {
  @apply mt-8;
}

.main__cn__wrapper  .error404__page .title  {
  @apply mb-4 font-bold;
}

.page-header.sticky-header{
  @apply bg-siteBG2
}
.page-header.sticky-header::before{
  @apply absolute content-[''] w-[100dvw] left-1/2 -translate-x-1/2 top-0 h-full bg-siteBG2 -z-1;
}
.view-course-mode {
  @apply inline-flex items-center gap-1 text-sm font-medium text-dark hover:bg-white transition-all duration-300 w-fit px-3 py-1 border border-solid border-borderColor rounded-lg;
}