.addButton {
  @apply flex items-center gap-2 bg-primary hover:bg-secondary text-white p-2 py-3 rounded-md transition-all hover:transition-all;
}

.viewCard {
  @apply flex flex-col gap-y-8 border-b border-solid border-borderColor pb-8 mb-8 last:mb-0 last:pb-0 last:border-none;
}

.addIconCard {
  @apply cursor-pointer w-11 h-11 bg-primary rounded-lg text-white flex justify-center items-center;
}
